<template>
	<div class="card mb-0 cursor-pointer" @click="abrir">
      <div class="card-buttons-top">
         <div class="card-body">
				<h6 class="limitador-2 font-20">{{ modulo.nome }}</h6>
            <div class="row card-info-bottom align-items-center">
               <div class="col-12 mt-2">
                  <p class="limitador-3"><i class="fas fa-info-circle font-12 color-theme me-1"></i> {{ modulo.descricao != null ? modulo.descricao : 'Sem descrição' }}</p>
               </div>
            </div>
			</div>
      </div>
      <img :src="modulo.nomeArquivo == null ? '' : modulo.nomeArquivo" class="card-img-top" alt="noticia" @error="imageError">
   </div>
</template>

<script>

export default {
	name: 'Modulo',
   props: ['modulo'],
	methods: {
		abrir : function () {
         this.$emit('abrir', this.modulo)
      },
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
		}
	}
}

</script>

<style scoped>

.card-buttons-top {
   display: flex;
	flex-wrap: wrap;
   justify-content: center;
   align-items: end;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background: #0006;
   transition: 0.3s;
}

.card-buttons-top:hover {
	background: rgba(0, 0, 0, 0.658);
}

.card-info-bottom * {
   filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
}

.card-info-bottom a {
   color: #ddd;
   cursor: pointer;
}

.card-info-bottom a:hover {
   text-decoration: underline !important;
}

h6 {
   color: #ddd;
   margin-bottom: 4px !important;
   font-weight: 700 !important;
	filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
}

p {
	color: #eee;
   font-size: 13px !important;
   margin-bottom: 0 !important;
	filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
	line-height: 1.4;
}

img {
	aspect-ratio: 3/4;
	object-fit: cover;
}

</style>