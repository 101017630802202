<template>
   <div class="row">
      <div class="col-12" v-if="pesquisa.resultado.length > 0">
         <div class="cards-2">
            <modulo v-for="(modulo, index) in pesquisa.resultado" :key="index" :modulo="modulo" @abrir="abrirModulo($event)"/>
         </div>
      </div>
      <div class="col-12 my-5 text-center" v-else>{{ $t('admMeusCursos.semResultados') }}</div>
   </div>
</template>

<script>

import { mapState } from 'vuex'
import modulo from '@/components/admin/meuscursos/Modulo.vue'


export default {
	name: 'MeusCursos',
	data: function() {
		return {
         pesquisa: {'nome': '', 'resultado': []},
         modulo: {'id': null, 'nome': null, 'descricao': null, 'codigo': null, 'nomeArquivo': null},
		}
	},
	components: {
      modulo
   },
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		})
	},
	methods: {
		searchMeusModulos : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.resultado = [];

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/searchMeusModulos',
            params: {
               id: this.dadosUsuario.id
            }
         }).then(response => {
				this.pesquisa.resultado = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      abrirModulo(modulo) {
         this.$router.push('/admClassroom/' + modulo.id);
      },
	},
	mounted() {
		this.searchMeusModulos()
	}
}

</script>